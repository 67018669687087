<template>
  <div class="market">
    <div class="market-l">
      <div class="l-title">市场行情</div>
      <div class="my-table">
        <MyTable :tableData="tableData" :configTable="configTable"></MyTable>
        <el-pagination
          v-if="tableData.length"
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <div class="market-c">
      <div class="tips">2017-2023年中国稻谷和稻谷进口量及增长情况</div>
      <v-chart class="v-chart" autoresize :option="option"></v-chart>
      <div class="tips">2021-2023年中国稻谷均价走势图</div>
      <v-chart class="v-chart" autoresize :option="lineOption"></v-chart>
    </div>

    <MyNav ref="MyNav"></MyNav>
  </div>
</template>

<script>
import * as echarts from "echarts/core";
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { BarChart, LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
]);
export default {
  provide: {
    [THEME_KEY]: "dark",
  },
  components: {
    VChart,
    MyTable: () => import("@/components/MyTable.vue"),
    MyNav: () => import("@/components/MyNav.vue"),
  },
  data() {
    return {
      lineOption: {
        // title: {
        //   text: "Stacked Line",
        // },
        backgroundColor: "transparent",
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["2021年", "2022年", "2023年"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },

        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月",
          ],
        },
        yAxis: {
          type: "value",
          name: "元/吨",
        },
        series: [
          {
            name: "2021年",
            type: "line",
            stack: "Total",
            data: [120, 132, 101, 134, 90, 230, 210, 120, 242, 234, 412, 356],
          },
          {
            name: "2022年",
            type: "line",
            stack: "Total",
            data: [220, 182, 191, 234, 290, 330, 310, 380, 202, 294, 382, 306],
          },
          {
            name: "2023年",
            type: "line",
            stack: "Total",
            data: [150, 232, 201, 154, 190, 330, 410, 220, 342, 234, 512, 456],
          },
        ],
      },
      option: {
        backgroundColor: "transparent",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },

        legend: {
          data: ["进口数量(万吨)", "数量增长率(%)"],
        },
        xAxis: [
          {
            type: "category",
            data: [
              "2017年",
              "2018年",
              "2019年",
              "2020年",
              "2021年",
              "2022年",
              "2023年",
            ],
            axisPointer: {
              type: "shadow",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "进口数量(万吨)",
            min: 0,
            max: 250,
            interval: 50,
          },
          {
            type: "value",
            name: "数量增长率(%)",
            min: 0,
            max: 25,
            interval: 5,
          },
        ],
        series: [
          {
            name: "进口数量(万吨)",
            type: "bar",

            data: [
              2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4,
              3.3,
            ],
          },

          {
            name: "数量增长率(%)",
            type: "line",
            yAxisIndex: 1,

            data: [
              2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2,
            ],
          },
        ],
      },
      total: 20,
      current_page: 1,
      tableData: [
        {
          shijian: "2024-03",
          pinlei: "稻谷",
          mingcheng: "离岸价",
          diqu: "全球平均",
          zhouqi: "月",
          danwei: "人民币/吨",
          shuzhi: "2893",
        },
        {
          shijian: "2024-02",
          pinlei: "稻谷",
          mingcheng: "离岸价",
          diqu: "全球平均",
          zhouqi: "月",
          danwei: "人民币/吨",
          shuzhi: "2500.27",
        },
        {
          shijian: "2024-01",
          pinlei: "稻谷",
          mingcheng: "离岸价",
          diqu: "全球平均",
          zhouqi: "月",
          danwei: "人民币/吨",
          shuzhi: "2487.34",
        },
        {
          shijian: "2023-12",
          pinlei: "稻谷",
          mingcheng: "离岸价",
          diqu: "全球平均",
          zhouqi: "月",
          danwei: "人民币/吨",
          shuzhi: "2528.01",
        },
        {
          shijian: "2023-11",
          pinlei: "稻谷",
          mingcheng: "离岸价",
          diqu: "全球平均",
          zhouqi: "月",
          danwei: "人民币/吨",
          shuzhi: "2527.33",
        },
        {
          shijian: "2023-10",
          pinlei: "稻谷",
          mingcheng: "离岸价",
          diqu: "全球平均",
          zhouqi: "月",
          danwei: "人民币/吨",
          shuzhi: "2519.05",
        },
        {
          shijian: "2023-09",
          pinlei: "稻谷",
          mingcheng: "离岸价",
          diqu: "全球平均",
          zhouqi: "月",
          danwei: "人民币/吨",
          shuzhi: "2514.56",
        },
        {
          shijian: "2023-08",
          pinlei: "稻谷",
          mingcheng: "离岸价",
          diqu: "全球平均",
          zhouqi: "月",
          danwei: "人民币/吨",
          shuzhi: "2598.23",
        },
        {
          shijian: "2023-07",
          pinlei: "稻谷",
          mingcheng: "离岸价",
          diqu: "全球平均",
          zhouqi: "月",
          danwei: "人民币/吨",
          shuzhi: "2590.55",
        },
        {
          shijian: "2023-06",
          pinlei: "稻谷",
          mingcheng: "离岸价",
          diqu: "全球平均",
          zhouqi: "月",
          danwei: "人民币/吨",
          shuzhi: "2562.29",
        },
      ],
      configTable: [
        {
          prop: "shijian",
          label: "时间",
          width: "80",
        },
        {
          prop: "pinlei",
          label: "品类",
          width: "120",
        },
        {
          prop: "mingcheng",
          label: "名称",
          width: "120",
        },
        {
          prop: "diqu",
          label: "地区",
          width: "120",
        },
        {
          prop: "zhouqi",
          label: "周期",
          width: "80",
        },
        {
          prop: "danwei",
          label: "单位",
          width: "120",
        },
        {
          prop: "shuzhi",
          label: "数值",
          width: "",
        },
      ],
      statList: [],
      isBoxShowNum: 14,
    };
  },
  methods: {
    // 切换分页
    handleCurrentChange(val) {
      // this.getBatchListApi();
      if (val == 1) {
        this.tableData = [
          {
            shijian: "2023-10",
            pinlei: "稻谷",
            mingcheng: "离岸价",
            diqu: "全球平均",
            zhouqi: "月",
            danwei: "人民币/吨",
            shuzhi: "528.01",
          },
          {
            shijian: "2022-01",
            pinlei: "稻谷",
            mingcheng: "离岸价",
            diqu: "全球平均",
            zhouqi: "月",
            danwei: "人民币/吨",
            shuzhi: "527.33",
          },
          {
            shijian: "2022-02",
            pinlei: "稻谷",
            mingcheng: "离岸价",
            diqu: "全球平均",
            zhouqi: "月",
            danwei: "人民币/吨",
            shuzhi: "519.05",
          },
          {
            shijian: "2022-03",
            pinlei: "稻谷",
            mingcheng: "离岸价",
            diqu: "全球平均",
            zhouqi: "月",
            danwei: "人民币/吨",
            shuzhi: "514.56",
          },
          {
            shijian: "2022-04",
            pinlei: "稻谷",
            mingcheng: "离岸价",
            diqu: "全球平均",
            zhouqi: "月",
            danwei: "人民币/吨",
            shuzhi: "598.23",
          },
          {
            shijian: "2022-05",
            pinlei: "稻谷",
            mingcheng: "离岸价",
            diqu: "全球平均",
            zhouqi: "月",
            danwei: "人民币/吨",
            shuzhi: "590.55",
          },
          {
            shijian: "2022-06",
            pinlei: "稻谷",
            mingcheng: "离岸价",
            diqu: "全球平均",
            zhouqi: "月",
            danwei: "人民币/吨",
            shuzhi: "562.29",
          },
          {
            shijian: "2022-07",
            pinlei: "稻谷",
            mingcheng: "离岸价",
            diqu: "全球平均",
            zhouqi: "月",
            danwei: "人民币/吨",
            shuzhi: "573.00",
          },
          {
            shijian: "2022-08",
            pinlei: "稻谷",
            mingcheng: "离岸价",
            diqu: "全球平均",
            zhouqi: "月",
            danwei: "人民币/吨",
            shuzhi: "500.27",
          },
          {
            shijian: "2022-09",
            pinlei: "稻谷",
            mingcheng: "离岸价",
            diqu: "全球平均",
            zhouqi: "月",
            danwei: "人民币/吨",
            shuzhi: "487.34",
          },
        ];
      } else {
        this.tableData = [
          {
            shijian: "2023-06",
            pinlei: "稻谷",
            mingcheng: "离岸价",
            diqu: "全球平均",
            zhouqi: "月",
            danwei: "人民币/吨",
            shuzhi: "511.55",
          },
          {
            shijian: "2023-08",
            pinlei: "稻谷",
            mingcheng: "离岸价",
            diqu: "全球平均",
            zhouqi: "月",
            danwei: "人民币/吨",
            shuzhi: "509.50",
          },
          {
            shijian: "2023-11",
            pinlei: "稻谷",
            mingcheng: "离岸价",
            diqu: "全球平均",
            zhouqi: "月",
            danwei: "人民币/吨",
            shuzhi: "501.34",
          },
          {
            shijian: "2023-01",
            pinlei: "稻谷",
            mingcheng: "离岸价",
            diqu: "全球平均",
            zhouqi: "月",
            danwei: "人民币/吨",
            shuzhi: "512.00",
          },
          {
            shijian: "2023-02",
            pinlei: "稻谷",
            mingcheng: "离岸价",
            diqu: "全球平均",
            zhouqi: "月",
            danwei: "人民币/吨",
            shuzhi: "501.06",
          },
          {
            shijian: "2023-03",
            pinlei: "稻谷",
            mingcheng: "离岸价",
            diqu: "全球平均",
            zhouqi: "月",
            danwei: "人民币/吨",
            shuzhi: "511.22",
          },
          {
            shijian: "2023-04",
            pinlei: "稻谷",
            mingcheng: "离岸价",
            diqu: "全球平均",
            zhouqi: "月",
            danwei: "人民币/吨",
            shuzhi: "474.83",
          },
          {
            shijian: "2023-05",
            pinlei: "稻谷",
            mingcheng: "离岸价",
            diqu: "全球平均",
            zhouqi: "月",
            danwei: "人民币/吨",
            shuzhi: "492.13",
          },
          {
            shijian: "2023-07",
            pinlei: "稻谷",
            mingcheng: "离岸价",
            diqu: "全球平均",
            zhouqi: "月",
            danwei: "人民币/吨",
            shuzhi: "521.55",
          },
          {
            shijian: "2023-09",
            pinlei: "稻谷",
            mingcheng: "离岸价",
            diqu: "全球平均",
            zhouqi: "月",
            danwei: "人民币/吨",
            shuzhi: "531.86",
          },
        ];
      }
      this.current_page = val;
    },
    lookApp() {
      this.isBoxShowNum = 13;
    },
  },
};
</script>

<style lang="less" scoped>
.market {
  width: 100%;
  height: 100vh;
  background: #071a2c;

  padding: 76px 0 0;
  display: flex;
  justify-content: space-between;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
  .tips {
    width: 100%;
    height: 33px;
    line-height: 33px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 28px;
  }
  .market-l {
    width: 60%;
    height: 100%;
    background: #142d4c;
    padding: 30px 58px;
    .l-title {
      font-size: 22px;
      font-weight: 400;
      color: #fff;
      position: relative;
      padding: 0 0 0 11px;
      margin-bottom: 27px;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 4px;
        height: 22px;
        background: #3e90e5;
        border-radius: 2px;
      }
    }
    .my-table {
      width: 100%;
      min-height: 690px;
    }
  }
  .market-c {
    height: 100%;
    width: 38%;
    background: #0e2138;
    margin: 0 16px;
    padding: 30px 58px;
    .v-chart {
      width: 100%;
      height: 44%;
    }
  }
}
</style>
